import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import DevicePage from './components/pages/DevicePage';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import './custom.css'

export default () => (
    <Layout>
    <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/device/:deviceId' component={DevicePage} />
        {/* <Route path='/signin-oidc' component={SignInOidc} /> */}
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
    </Layout>
);
