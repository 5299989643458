import { Grid, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import NavMenu from './NavMenu';

const useStyles = makeStyles((theme: Theme) => ({
    mainContainer: {
        marginTop: 20
    }
}))
export default function Layout(props:any) {
    const classes = useStyles();
        return (
            <React.Fragment>
                <NavMenu />
                <Grid container className={classes.mainContainer}>
                    {props.children}
                </Grid>
            </React.Fragment>
        );
    
}