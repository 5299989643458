const { NODE_ENV } = process.env;
const API_BASE_URL = NODE_ENV === 'development'? 'https://localhost:5001/' : 'https://smart-remote-api.fpsoftwares.com/'

export class SmartHomeApiClient {
    constructor(accessToken: string) {
        this.accessToken = accessToken;
    }

    accessToken: string;

    async getDevices() {
        let response = await fetch(`${API_BASE_URL}devices`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `bearer ${this.accessToken}`
            }
        });

        return await response.json();
    }

    async getDeviceStates(deviceId: string) {

        let response = await fetch(`${API_BASE_URL}devices/${deviceId}/states`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `bearer ${this.accessToken}`
            }
        });

        return await response.json();
    }
    async toggleLock(device: any) {
        const { deviceId, deviceHostId } = device;
        await fetch(`${API_BASE_URL}control`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `bearer ${this.accessToken}`
            },
            body: JSON.stringify({ deviceId, deviceHostId, data: 'toggle_lock' })
        });
    }

    async captureStill(device: any) {
        const { deviceId, deviceHostId } = device;
        
        return await fetch(`${API_BASE_URL}control`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `bearer ${this.accessToken}`
            },
            body: JSON.stringify({ deviceId, deviceHostId, data: 'capture_still' })
        });
    }
}