import * as React from 'react';
import { connect } from 'react-redux';
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import userManager from '../user-manager';
import { deviceActions } from '../store/Device'
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../store';
import InboxIcon from '@material-ui/icons/Inbox';
import StraightenIcon from '@material-ui/icons/Straighten';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import LoginButton from './Login';
import { useAuth0 } from '@auth0/auth0-react';

const Home = (props: any) => {

    const { loginWithRedirect } = useAuth0();
    const { devices, getDevices, history } = props;
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [devicesFetched, setDevicesFetched] = React.useState(false);
    
    const handleOnItemClick = (deviceId: string) => () => {
        if (history) {
            history.push(`/device/${deviceId}`);
        }
    }

    if (user) {
        (async () => {
            var accessToken = await getAccessTokenSilently();
            if (!devicesFetched && (!devices || devices && devices.length === 0)) {
                getDevices(accessToken);
                setDevicesFetched(true);
            }
        })()


        return <div>

            <List component="nav" aria-label="smart devices">
                {
                    devices.map((d: any) => <ListItem button onClick={handleOnItemClick(d.deviceId)}>
                        <ListItemIcon>
                            <DeviceIcon deviceType={d.deviceType} />
                        </ListItemIcon>
                        <ListItemText primary={d.name} />
                    </ListItem>)
                }
            </List>
        </div>
    }
    console.log(isAuthenticated);
    return (
        <div><LoginButton/></div>
    )
}

const actions = { ...deviceActions }
export default connect(mapStateToProps, dispatch => bindActionCreators(actions, dispatch))(Home);

function mapStateToProps(state: ApplicationState, ownProps: any) {
    return {
        ...state.oidc,
        ...state.devices,
        ...ownProps
    }
}


function DeviceIcon(props: { deviceType: string }) {
    const { deviceType } = props;

    switch (deviceType) {
        case "garage door":
            return <StraightenIcon />
        case "car starter":
            return <DirectionsCarIcon />
    }

    return <DevicesOtherIcon />
}