import { Action, Reducer } from 'redux';
import { SmartHomeApiClient } from '../api/SmartHomeAPI';
import { AppThunkAction } from './';
import { useAuth0 } from '@auth0/auth0-react';

export interface DeviceState {
    deviceId: string;
    name: string;
    value: string;
}

export interface Device {
    deviceId: string;
    deviceHostId: string;
    name: string;
    capabilities: string[];
    states: DeviceState[]
}

interface DevicesType {
    type: "GET_DEVICES",
    devices: Device[]
}

interface ToggleLockType {
    type: "TOGGLE_LOCK",
    deviceId: string
}

interface CaptureStillType {
    type: "CAPTURE_STILL",
    deviceId: string,
    data: any
}

interface DeviceStateType {
    type: "GET_DEVICE_STATES",
    deviceId: string,
    states: DeviceState[]
}

export interface DeviceReducer {
    devices: Device[]
}

type KnownAction = DevicesType | DeviceStateType | ToggleLockType | CaptureStillType;

export const deviceActions = {

    getDevices: (accessToken: string): AppThunkAction<KnownAction> => async (dispatch:any, getState): Promise<Device[]> => {

            if(accessToken){
            let apiClient = new SmartHomeApiClient(accessToken);
            let devices = await apiClient.getDevices();

            dispatch({ type: "GET_DEVICES", devices });

            return devices;
            }
            return [];
    },
    
    getDeviceStates: (deviceId:string): AppThunkAction<KnownAction> => async (dispatch, getState): Promise<DeviceState[]> => {

        let user = getState().oidc.user;
        if (user) {
            let apiClient = new SmartHomeApiClient(user.access_token);

            let states = await apiClient.getDeviceStates(deviceId);

            dispatch({ type: "GET_DEVICE_STATES", states, deviceId });

            return states;
        }

        return [];
    },

    toggleLock: (device: Device, accessToken: string): AppThunkAction<KnownAction> => async (dispatch, getState): Promise<DeviceState[]> => {

        if (accessToken) {
            let apiClient = new SmartHomeApiClient(accessToken);
            const { deviceId } = device;
            await apiClient.toggleLock(device);
            
            dispatch({ type: "TOGGLE_LOCK", deviceId });
        }

        return [];
    },

    captureStill: (device: Device, accessToken: string): AppThunkAction<KnownAction> => async (dispatch, getState): Promise<DeviceState[]|any> => {

        if (accessToken) {
            let apiClient = new SmartHomeApiClient(accessToken);
            const { deviceId } = device;
            var data = await apiClient.captureStill(device);
            
            dispatch({ type: "CAPTURE_STILL", deviceId, data });
            return data;
        }

    }
}

export const reducer: Reducer<DeviceReducer> = (state: DeviceReducer = { devices: [] }, incomingAction: Action) => {

    if (state === undefined)
        return { devices: [] };

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "GET_DEVICES":
            {
                let { devices } = action;
                return { devices };
            }
        case "GET_DEVICE_STATES":
            {
                let { deviceId } = action;
                let device = state.devices.find(device => device.deviceId === deviceId);
                if (device) {
                    device.states = action.states;
                    let devices: Array<Device> = [...state.devices];
                    return { devices };
                }
            }
        case "TOGGLE_LOCK":
            {
                let { deviceId } = action;
                let device = state.devices.find(device => device.deviceId === deviceId);
                if (device) {
                    let lockIndex = device.states.findIndex(p => p.name === "lockState");
                    device.states[lockIndex].value = toggle(device.states[lockIndex].value);
                    let devices: Array<Device> = [...state.devices];
                    return { devices };
                }

                return { ...state };
            }
            case "CAPTURE_STILL":{

                return {...state};
            }
    }

    return { ...state };

    function toggle(state:string) {
        return state === "LOCKED" ? "UNLOCKED" : "LOCKED";
    }
}