import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import userManager from '../user-manager';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import LoginButton from './Login';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function ButtonAppBar(props:any) {

    const handleLogin = () => {
        userManager.signinRedirect();
    }

    const { user } = props;

    const classes = useStyles();
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Smart Home Controller
                        </Typography>
                        {!user && <LoginButton/>}
                    </Toolbar>
                </AppBar>
            </div>
        );
}

export default connect(mapStateToProps)(ButtonAppBar)

function mapStateToProps(state: ApplicationState) {
    return { ...state.oidc }
}