import { useAuth0 } from '@auth0/auth0-react';
import { Breadcrumbs, Button, Grid, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { type } from 'os';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Device, deviceActions } from '../../store/Device';
import userManager from '../../user-manager';



const useStyles = makeStyles((theme: Theme) => ({
    unlocked: {
        width: '100%',
        height: '100%',
        color: 'red'
    },
    devicePaper: {
        width: '100%'
    },
    imageContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100
    },
    image: {
        height: '100%',
        width: '100%'
    }
}));

const DevicePage = (props: any) => {

    const classes = useStyles();

    const { loginWithRedirect, user, getAccessTokenSilently } = useAuth0();
    let { deviceId } = useParams<{ deviceId: string }>();
    let [stateFetched, setStateFetched] = React.useState(false);
    const { devices, getDeviceStates, getDevices, toggleLock, captureStill, history } = props;
    const [image, setSImage] = React.useState<any>(null);

    if (!user) {
        loginWithRedirect()
        return <div>logging in...</div>;
    }

    let device = devices.find((d: any) => d.deviceId === deviceId) as Device;
    if (!device) {
        if (devices && devices.length === 0)
            getDevices();

        return <div>Ooops something went wrong.</div>
    }
    else if (!stateFetched && deviceId) {
        getDeviceStates(deviceId);
        setInterval(() => getDeviceStates(deviceId), 5000);
        setStateFetched(true);
    }

    const handleOnCaptureClick = async () => {

        let accessToken = await getAccessTokenSilently();
        let data = (await captureStill(device, accessToken)) as Response;
var blob = await data.blob();
        
                setSImage(URL.createObjectURL(blob));
                

            

            console.log(data);
        
    }
    const getLockState = () => {
        const lockState = device.states.find(s => s.name === "lockState");
        return lockState && lockState.value;
    }
    const handleOnToggleClick = async () => {
        //we should not be doing this here
        var accessToken = await getAccessTokenSilently();
        toggleLock(device, accessToken);
    }
    const handleClick = (path?: string) => (event: any) => {
        event.preventDefault();

        if (!path) return;

        if (history) {
            history.push(path);
        }
    }

    let lockState = getLockState();

    return <Grid container>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick("/")}>
                Home
            </Link>
            <Typography color="textPrimary">{device.name}</Typography>
        </Breadcrumbs>
        <Grid container justify="center">
            <Grid item>
                <Typography variant="h4">{device.name}</Typography>
            </Grid>
        </Grid>
        <Paper className={classes.devicePaper}>
            <Grid container justify="center">
                <Grid item>
                    {
                        image ? <Grid container className={classes.imageContainer}>
                            <Grid item>
                                <Button onClick={() => setSImage(undefined)}>X</Button>
                            </Grid>
                            <Grid item alignItems='center'>
                                <img src={image} className={classes.image}/>
                            </Grid>
                            </Grid> :
                            <ToggleButton state={lockState} onClick={handleOnToggleClick} />
                    }                </Grid>
            </Grid>
        </Paper>
        <Grid>
            <Grid container justify="center">
                <Grid item>
                    <CaptureButton onClick={handleOnCaptureClick} />
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}

const CaptureButton = ({ onClick }: { onClick: any }) => {
    return <Button onClick={onClick}>
        Take a picture
    </Button>
}

type StateType = "LOCKED" | "UNLOCKED";
function ToggleButton(props: { state: StateType | string | undefined, onClick?: any }) {

    const classes = useStyles();
    let { state, onClick } = props;
    return (state === "LOCKED" ? <Button onClick={onClick}>
        <Grid container direction="column">
            <Grid item md={12} xs={12} sm={12}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classes.unlocked} ><path fill="none" d="M0 0h24v24H0z" /><path fill="#6bc093" d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z" />
                </svg>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
                <div>Locked</div>
            </Grid>
        </Grid>
    </Button> : <Button onClick={onClick}>
        <Grid container direction="column">
            <Grid item md={12} xs={12} sm={12}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classes.unlocked}><path fill="none" d="M0 0h24v24H0z" /><path fill="#ff6464" d="M7 10h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 0 1 13.262-3.131l-1.789.894A5 5 0 0 0 7 9v1zm-2 2v8h14v-8H5zm5 3h4v2h-4v-2z" />
                </svg>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
                <div>Unlocked</div>
            </Grid>
        </Grid>
    </Button>);
}

const actions = { ...deviceActions };
export default connect(mapStateToProps, dispatch => bindActionCreators(actions, dispatch))(DevicePage);

function mapStateToProps(state: any) {
    return { ...state.devices, ...state.oidc }
}